* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
